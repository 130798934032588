import React, { useState, useRef } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import logo from "../../images/shib-ai-nu-logo.png";
import { useClipboard } from "use-clipboard-copy";
import { Chart } from "react-google-charts";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

export const data = [
  ["tokens", "supply (in millions)"],
  ["presale", 30],
  ["liquidity and listings", 20],
  ["community", 50],
];

export const options = {
  pieSliceText: "label",
  pieStartAngle: 100,
  backgroundColor: "transparent",
  titleTextStyle: {
    color: "#FFFFFF",
    fontSize: 40,
  },
  allowHTML: true,
  legend: { position: "right", textStyle: { color: "#FFFFFF", fontSize: 14 } },
  //colors: ['#dcf3ff', '#baf2ef', '#a2d2df', '#396d7c', '#257ca3'],
  chartArea: { left: 10, top: 20, width: "100%", height: "100%" },
};

function TokenomicsComponent() {
  const [show, setShow] = useState(false);

  const clipboardContract = useClipboard({
    onSuccess() {
      setShow(true);
    },
    onError() {
      console.log("Failed to copy text!");
    },
  });

  const clipboardPublic = useClipboard({
    onSuccess() {
      setShow(true);
    },
    onError() {
      console.log("Failed to copy text!");
    },
  });

  return (
    <>
      <Container>
        <Row>
          <Col>
            <div className="p-4">
              <h1 className="h2 mt-5">
                <span className="aisColors">Shib.AI.nu</span>{" "}
                <small className="text-body-secondary">
                  tokenomics total supply 1,000,000,000,000
                </small>
              </h1>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <div className="p-4">
              <Card
                style={{ padding: "10px 10px 0px 10px" }}
                className="bg-transparent borderCardRoadmap"
              >
                <Card.Img variant="top" src={logo} className="img-fluid" />
                <Card.Body>
                  <Card.Title></Card.Title>
                  <Card.Text>
                    <Row>
                      <Col>
                        <div className="pt-1 pb-1">
                          name: <br />
                          <h6>
                            {" "}
                            <span className="aisColors">Shib.AI.nu</span>
                          </h6>
                        </div>
                      </Col>
                      <Col>
                        <div className="pt-1 pb-1">
                          presale starts: <br />
                          <h6>08/19/2023</h6>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="pt-1 pb-1">
                          ticker: <br />
                          <h6>
                            {" "}
                            <span className="aisColors">$SHAI</span>
                          </h6>
                        </div>
                      </Col>
                      <Col>
                        <div className="pt-1 pb-1">
                          presale ends: <br />
                          <h6>10/18/2023</h6>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="pt-1 pb-1">
                          total supply: <br />
                          <h6>1,000,000,000,000</h6>
                        </div>
                      </Col>
                      <Col>
                        <div className="pt-1 pb-1">
                          accepted Currency: <br />
                          <h6>
                            {" "}
                            <span className="aisColors">ETH</span>
                          </h6>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="pt-1 pb-1">
                          contract address: <br />
                          <h6>
                            coming soon{" "}
                            <Button
                              size="sm"
                              onClick={clipboardContract.copy}
                              className="shaiInput"
                            >
                              <i class="bi bi-clipboard"></i>
                            </Button>
                          </h6>
                          <input
                            type="text"
                            value="coming soon"
                            hidden
                            ref={clipboardContract.target}
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="pt-1 pb-1">
                          public sale address: <br />
                          <h6>
                            coming soon{" "}
                            <Button
                              size="sm"
                              onClick={clipboardPublic.copy}
                              className="shaiInput"
                            >
                              <i class="bi bi-clipboard"></i>
                            </Button>
                          </h6>
                          <input
                            type="text"
                            value="coming soon"
                            hidden
                            ref={clipboardPublic.target}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Toast
                          onClose={() => setShow(false)}
                          show={show}
                          delay={5000}
                          autohide
                          bg="success"
                          className=""
                        >
                          <Toast.Body>successfully copied!</Toast.Body>
                        </Toast>
                      </Col>
                    </Row>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="p-4">
              <Chart
                chartType="PieChart"
                data={data}
                options={options}
                width={"100%"}
                height={"400px"}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default TokenomicsComponent;
