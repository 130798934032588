import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { MetamaskProvider } from "@0xcert/ethereum-metamask-provider";
import Web3 from "web3";
import ETHicon from "../../images/eth-icon.png";
import shaiToken from "../../images/shib.ai.nu-token.png";

import NavigationComponent from "../../components/NavigationComponent/NavigationComponent";

const TOKEN_ADDRESS = "0xb8F1359a7F46a258ef3C4c0636EF97b21a7668AC";
const CROWDSALE_ADDRESS = "0x80241F96b9E1a145978e81B8D2A91718E8290351";

function Webby() {
  const [isVisible, setIsVisible] = useState(false);
  const [shai = 100, setShai] = useState(null);
  const [eth, setEth] = useState(null);

  const handleShaiInput = (event) => {
    setShai(event.target.value);
    setEth(event.target.value * 0.00000001);
  };

  const handleEthInput = (event) => {
    setEth(event.target.value);
    setShai(event.target.value / 0.00000001);
  };

  //const onClick = () => setTextHidden(!isTextHidden);

  async function enableMetamask() {
    try {
      const provider = new MetamaskProvider();
      let connected = false;
      let boolean = await provider.isEnabled();
      if (!boolean) {
        let conn = await provider.enable();

        if (conn) {
          connected = true;
          setIsVisible(true);
          alert("Connected");
        } else {
          alert("Please install metamask");
          setIsVisible(false);
        }
        boolean = true;
      } else {
        alert("Already connected or install extension");
      }
    } catch (err) {
      alert(err.message);
      setIsVisible(false);
    }
  }

  async function enableMetamaskwithtoken() {
    const tokenAmount = document.getElementById("tokenAmount").value;
    var amount = tokenAmount;
    try {
      const provider = new MetamaskProvider();
      let connected = false;
      let boolean = await provider.isEnabled();
      if (!boolean) {
        let conn = await provider.enable();
        if (conn) {
          connected = true;
          // alert('Connected')
        } else {
          alert("Please install metamask");
        }
        boolean = true;
      } else {
        alert("Already connected or install extension");
      }
    } catch (err) {
      alert(err.message);
    }

    const CONTRACT_ABI = [
      {
        inputs: [
          { internalType: "contract IERC20", name: "token_", type: "address" },
          { internalType: "uint256", name: "price_", type: "uint256" },
          { internalType: "uint256", name: "lockup_", type: "uint256" },
          { internalType: "uint256", name: "maxPerWallet_", type: "uint256" },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "buyer",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amountOfETH",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amountOfTokens",
            type: "uint256",
          },
        ],
        name: "BuyTokens",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "previousOwner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "newOwner",
            type: "address",
          },
        ],
        name: "OwnershipTransferred",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "account",
            type: "address",
          },
        ],
        name: "Paused",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "account",
            type: "address",
          },
        ],
        name: "Unpaused",
        type: "event",
      },
      {
        inputs: [{ internalType: "address", name: "account", type: "address" }],
        name: "availableBalance",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "buyTokens",
        outputs: [
          { internalType: "uint256", name: "tokenAmount", type: "uint256" },
        ],
        stateMutability: "payable",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
        name: "claim",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
        name: "depositTokens",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "maxTokensPerWallet",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "owner",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "pause",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "paused",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "price",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "_amount", type: "uint256" }],
        name: "setMaxTokensPerWallet",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "_amount", type: "uint256" }],
        name: "setPrice",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "token_", type: "address" }],
        name: "setTokenContract",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "tokensPerEth",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "newOwner", type: "address" },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "unpause",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "withdrawBNB",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "withdrawTokens",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    ];

    if (typeof Web3 !== "undefined") {
      let w3js = new Web3(window.ethereum);

      w3js.eth.getAccounts(async function (err, res) {
        const ethAddressList = [];

        if (res) {
          const tokenInst = await new w3js.eth.Contract(
            CONTRACT_ABI,
            CROWDSALE_ADDRESS
          );

          await tokenInst.methods
            .buyTokens()
            .send({
              from: res[0],
              gas: "210000",
              gasPrice: w3js.utils.toWei("16", "gwei"),
              value: w3js.utils.toWei(
                JSON.stringify(amount / 72000000),
                "ether"
              ),
              data: tokenInst.methods.withdrawTokens(),
            })

            .once("recepient", (recepient) => {
              window.alert("sucess");
            })

            .on("transactionHash", function (hash) {
              console.log("hash: " + hash);
            })
            .on("confirmation", function (confirmationNumber, receipt) {
              console.log("confirmationNumber: " + confirmationNumber);
              console.log("1receipt: " + JSON.stringify(receipt));
            })
            .on("receipt", function (receipt) {
              // receipt example
              console.log("receipt: " + receipt);
            });
        } else {
          let obj = {
            address: "Please authorise you account to view",

            balance: "Please authorise you account to view",
          };
          ethAddressList.push(obj);
        }
      });
    } else {
      alert("Web3 Not Supported/not autorized");
    }
  }

  return (
    <>
      <NavigationComponent fn={enableMetamask}></NavigationComponent>
      <Row className="mb-12">
        <Form.Group as={Col} md="6" controlId="validationCustom01">
          <Form.Label>
            Amount in <span className="aisColors">$SHAI</span> you receive
          </Form.Label>

          <InputGroup className="mb-2">
            <InputGroup.Text>
              {" "}
              <Image src={shaiToken} fluid className="inputIcon" rounded />
            </InputGroup.Text>
            <Form.Control
              id="tokenAmount"
              placeholder="amount of $SHAI token"
              aria-label="amount of $SHAI token"
              min="1000000"
              type="number"
              value={shai}
              onChange={handleShaiInput}
            />
          </InputGroup>

          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="validationCustom02">
          <div>
            <Form.Label>
              Amount in <span className="aisColors">ETH</span> you pay
            </Form.Label>
            <InputGroup className="mb-2">
              <InputGroup.Text>
                {" "}
                <Image src={ETHicon} fluid className="inputIcon" />
              </InputGroup.Text>
              <Form.Control
                placeholder="amount of $ETH to pay"
                aria-label="amount of $ETH to pay"
                type="number"
                value={eth}
                onChange={handleEthInput}
              />{" "}
            </InputGroup>

            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </div>
        </Form.Group>
      </Row>
      <Row>
        <Col>
          <div className="pt-4">
            <div className="text-center">
              <Button
                variant="primary"
                size="lg"
                style={{ display: isVisible ? "inline-block" : "none" }}
                className="shibainuButton {
                  "
                onClick={enableMetamaskwithtoken}
              >
                <i class="bi bi-cash-coin"></i> buy $SHAI now
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Webby;
