import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Accordion from "react-bootstrap/Accordion";
import shibAiNiFaqs from "../../images/shib-ai-nu-faqs.png";

function FaqComponent() {
  return (
    <>
      <Container className="gx-0" id="FaqHome">
        <Row>
          <Col>
            <div className="p-4">
              <h1 className="h2 mt-5">
                <span className="aisColors">Shib.AI.nu</span>{" "}
                <small className="text-body-secondary">
                  {" "}
                  most common questions
                </small>
              </h1>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs="12" md="6">
            <div className="p-4">
              <Image src={shibAiNiFaqs} fluid />
            </div>
          </Col>
          <Col xs="12" md="6">
            <div className="p-4">
              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>How to buy $SHAI</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      1. Create an account at binance.com, kraken.com,
                      bitpanda.com, kukoin.com or an exchanger of your choice.
                      Exchange FIAT (Dollar, Euro, Franc) for Ethereum ($ETH).
                    </p>
                    <p>
                      2. The safest place to buy{" "}
                      <span className="aisColors">$SHAI</span> is on&nbsp;
                      <a
                        href="https://www.shib-ai-nu.com"
                        target="_blank"
                        className="aisLinkColor"
                        rel="noreferrer"
                      >
                        shib-ai-nu.com
                      </a>
                      . Visit{" "}
                      <a
                        href="https://www.shib-ai-nu.com"
                        target="_blank"
                        className="aisLinkColor"
                        rel="noreferrer"
                      >
                        shib-ai-nu.com
                      </a>
                      . and click “connect wallet” MetaMask will ask for your
                      signature, go ahead and sign it.
                    </p>
                    <p>
                      3. Open your Google Chrome and visit metamask.io. Download
                      the MetaMask chrome extension and set up a wallet. On
                      mobile? Get MetaMask’s app for iPhone or Android.
                    </p>
                    <p>
                      4. Set your slippage to 3-4% and enter and the amount of
                      $ETH you would like to swap for{" "}
                      <span className="aisColors">$SHAI</span>.
                    </p>
                    <p>
                      5. Transfer $ETH to your new MetaMask wallet from your
                      existing wallet (e.g. Trust Wallet, Coinbase, or Binance),
                      or buy $ETH directly within MetaMask.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header> What is $SHAI?</Accordion.Header>
                  <Accordion.Body>
                    <p>$SHAI is an ERC-20 Token</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header> Where can i hold $SHAI?</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Any wallet that supports ERC-20 tokens will support $SHAI
                      (i.e. Trust Wallet, Coinbase, or Metamask).
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    {" "}
                    Is $SHAI on Binance Smart Chain (BSC)?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      No, $SHAI is not on BSC. Be careful of counterfeits and
                      clones. Please check our “How to buy?" section above.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header> Is $SHAI stackable?</Accordion.Header>
                  <Accordion.Body>
                    <p>We are also working on it.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    {" "}
                    Why am I getting an error when trying to buy on $SHAI or
                    Uniswap?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      This is likely due to slippage. Click on the gear button
                      in the top right of the window and set your slippage to
                      4%.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    have an inspiration, an idea, found a bug, have a
                    suggestions or just say hello?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Please{" "}
                      <a
                        href="mailto:contact@shib-ai-nu.com"
                        className="aisLinkColor"
                      >
                        get in touch{" "}
                        <i class="bi bi-hand-index-thumb-fill smallIcon"></i>
                      </a>
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default FaqComponent;
