import React, { useEffect, useState } from "react";
import moment from "moment";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const targetTime = moment("2023-08-19");

function Timer() {
  const [currentTime, setCurrentTime] = useState(moment());
  const timeBetween = moment.duration(targetTime.diff(currentTime));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Row className="counter g-2">
        <Col md="2" sm="4">
          {timeBetween.years()}yr{" "}
        </Col>
        <Col md="2" sm="4">
          {timeBetween.months()}m{" "}
        </Col>
        <Col md="2" sm="4">
          {timeBetween.days()}d{" "}
        </Col>
        <Col md="2" sm="4">
          {timeBetween.hours()}h{" "}
        </Col>
        <Col md="2" sm="4">
          {timeBetween.minutes()}min{" "}
        </Col>
        <Col md="2" sm="4">
          {timeBetween.seconds()}s{" "}
        </Col>
      </Row>
    </>
  );
}

export default Timer;
