import React from "react";
import "./App.scss";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NavigationComponent from "./components/NavigationComponent/NavigationComponent";
import HeaderComponent from "./components/HeaderComponent/HeaderComponent";
import ProjectComponent from "./components/ProjectComponent/ProjectComponent";
import TokenomicsComponent from "./components/TokenomicsComponent/TokenomicsComponent";
import RoadmapComponent from "./components/RoadmapComponent/RoadmapComponent";
import RewardsComponent from "./components/RewardsComponent/RewardsComponent";
import FooterComponent from "./components/FooterComponent/FooterComponent";
import FaqComponent from "./components/FaqComponent/FaqComponent";
import ScrollToTopButtonComponent from "./components/ScrollToTopButtonComponent/ScrollToTopButtonComponent";

import WhitepaperComponent from "./components/WhitepaperComponent/WhitepaperComponent";

import AiscubesComponent from "./components/AiscubesComponent/AiscubesComponent";

function App() {
  return (
    <>
      <Container fluid className="gx-0" id="ShaiHome">
        <Row>
          <Col>
            <NavigationComponent />
            <HeaderComponent />
          </Col>
        </Row>

        <Row id="ShaiProject">
          <Col>
            <ProjectComponent />
          </Col>
        </Row>
        <hr />
        <Row id="ShaiTokenomics">
          <Col>
            <TokenomicsComponent />
          </Col>
        </Row>
        <hr />
        <Row id="ShaiRoadmap">
          <Col>
            <RoadmapComponent />
          </Col>
        </Row>
        <hr />
        <Row id="ShaiRewards">
          <Col>
            <RewardsComponent />
          </Col>
        </Row>
        <hr />
        <Row id="ShaiAiscubes">
          <Col>
            <AiscubesComponent />
          </Col>
        </Row>
        <hr />
        <Row id="ShaiFaq">
          <Col>
            <FaqComponent />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <FooterComponent />
          </Col>
        </Row>

        <WhitepaperComponent />

        <ScrollToTopButtonComponent />
      </Container>
    </>
  );
}

export default App;
