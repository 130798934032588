import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import thinking from "../../images/shib.ai.nu-connected-thinking.png";

import machineLearning from "../../images/shib.ai.nu-power-generation.png";

function ProjectComponent() {
  return (
    <>
      <Container className="bgThinking">
        <Row>
          <Col xs={12} md={6}>
            <div className="p-4">
              <h1 className="h2 mt-5">
                <small className="text-body-secondary">
                  connected thinking with{" "}
                </small>
                <span className="aisColors">Shib.AI.nu</span>!
              </h1>
              <p className="textSize">
                With <span className="aisColors">Shib.AI.nu</span> you can
                finally break down the barriers between your thoughts and ideas
                and unleash your creativity like never before. This innovative
                product is designed to help you connect the dots, find patterns
                and unlock new insights you never thought possible.
              </p>
              <p>
                <span className="aisColors">Shib.AI.nu</span> is perfect for
                anyone looking to improve their critical thinking skills, boost
                their problem solving skills and wants to improve their general
                cognitive performance. Whether you are studying, working or just
                want to stay sharp and focused,{" "}
                <span className="aisColors">Shib.AI.nu</span> is the ideal
                solution.
              </p>
              <p>
                So what makes <span className="aisColors">Shib.AI.nu</span> so
                special? First off, it's incredibly easy to use. With just a few
                clicks, you can create a mind map, brainstorm new ideas, and
                visualize complex concepts in a way that makes sense to you.
                Also, <span className="aisColors">Shib.AI.nu</span> is fully
                customizable so you can adapt it to your specific needs and
                preferences.
              </p>
              <p>
                But that's not all -{" "}
                <span className="aisColors">Shib.AI.nu</span> also has a number
                of powerful features that make it the ultimate tool for
                connected thinking. For example, you can easily share your mind
                maps with others, collaborate in real time, and even export your
                work to other formats like PDF or Microsoft Word.
              </p>
              <p>
                And the best part? <span className="aisColors">Shib.AI.nu</span>{" "}
                is built on a secure and reliable platform, so you can rest
                assured that your data is always safe and secure. With{" "}
                <span className="aisColors">Shib.AI.nu</span> you can focus on
                what really matters - your ideas, your creativity and your
                potential.
              </p>
              <p>
                So why wait? Try <span className="aisColors">Shib.AI.nu</span>{" "}
                today and discover the power of networked thinking!
              </p>
            </div>
          </Col>
          <Col className="marginAuto" xs={12} md={6}>
            <div className="p-4">
              <img src={thinking} alt="ais faq image" className="img-fluid" />
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="marginAuto" xs={12} md={6}>
            <div className="p-4">
              <img
                src={machineLearning}
                alt="ais faq image"
                className="img-fluid"
              />
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="p-4">
              <h1 className="h2">
                The future of content generation is here -{" "}
                <span className="aisColors">Shib.AI.nu</span>!
              </h1>
              <p>
                This groundbreaking AI platform generates images, memes, text,
                videos, audios and more to take your online presence to the next
                level.
              </p>
              <p>
                <span className="aisColors">Shib.AI.nu</span> is the perfect
                solution for anyone looking for a quick and easy way to create
                quality content. With just a few clicks you can access{" "}
                <span className="aisColors">Shib.AI.nu</span> and turn your
                ideas into reality. Whether you are running a business, building
                a brand, or just looking to improve your social media presence,{" "}
                <span className="aisColors">Shib.AI.nu</span> is the answer to
                all your needs.
              </p>
              <p>
                The AI technology behind{" "}
                <span className="aisColors">Shib.AI.nu</span> is incredibly
                advanced and uses the latest algorithms to deliver the best
                results. The platform is able to understand your needs and offer
                you the perfect solution. You can choose from a variety of
                options to ensure the generated content is tailored to your
                needs.
              </p>
              <p>
                With <span className="aisColors">Shib.AI.nu</span>, you can
                create high-quality images and videos that engage your audience
                and get your message across effectively. You can also generate
                copy that showcases your brand and products in the best possible
                way. The platform is also capable of creating memes and other
                viral content that will improve your online presence and
                increase your reach.
              </p>
              <p>
                <span className="aisColors">Shib.AI.nu</span> is easy to use and
                requires no prior knowledge. You can get started right away and
                create quality content that will delight your target audience.
                The platform also offers a wide range of customization options
                to ensure that the generated content fits your needs exactly.
              </p>
              <p>
                Overall, <span className="aisColors">Shib.AI.nu</span> is the
                perfect solution for anyone looking for a quick and easy way to
                create quality content. With its advanced AI technology and
                user-friendly interface, it is the future of content generation.
                Try it today and experience the difference!
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ProjectComponent;
