import React from "react";
import logo from "../../images/shib-ai-nu-logo.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import WhitepaperComponent from "../WhitepaperComponent/WhitepaperComponent";

function FooterComponent() {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <Container>
        <Row>
          <Col xs={12} md={4}>
            <div className="p-4">
              <img
                src={logo}
                height="30"
                className="d-inline-block align-top img-fluid"
                alt="the $AiS project"
              />
              <br />
              <br />
              Ulica Maršal
              <br />
              85356 Bar / Utjeha
              <br />
              Montenegro
              <br />
              Email:{" "}
              <a href="mailto:contact@shib-ai-nu.com" className="aisLinkColor">
                get in touch{" "}
                <i class="bi bi-hand-index-thumb-fill smallIcon"></i>
              </a>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="p-4 whitepaperFooter">
              <h1 className="h4">
                <span className="aisColors">Links</span>
              </h1>
              <a href="#ShaiHome" className="aisLinkColor">
                home
              </a>
              <br />
              <a href="#ShaiProject" className="aisLinkColor">
                project
              </a>
              <br />
              <a href="#ShaiTokenomics" className="aisLinkColor">
                tokenomics
              </a>
              <br />
              <a href="#ShaiRoadmap" className="aisLinkColor">
                roadmap
              </a>
              <br />
              <a href="#ShaiRewards" className="aisLinkColor">
                rewards
              </a>
              <br />
              <a href="#ShaiAiscubes" className="aisLinkColor">
                aiscubes
              </a>
              <br />
              <WhitepaperComponent />
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="p-4">
              <h1 className="h4">
                <span className="aisColors">social media</span>
              </h1>

              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-disabled">
                    take a look at our FAQs
                  </Tooltip>
                }
              >
                <span className="d-inline-block">
                  <a
                    href="#ShaiFaq"
                    className="text-white btn btn-link aisLinkColor"
                  >
                    <i class="bi bi-megaphone-fill"></i>
                  </a>
                </span>
              </OverlayTrigger>

              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-disabled">visit us on Twitter</Tooltip>
                }
              >
                <span className="d-inline-block">
                  <a
                    href="https://twitter.com/shib_ai_nu"
                    className="text-white btn btn-link aisLinkColor"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i class="bi bi-twitter"></i>
                  </a>
                </span>
              </OverlayTrigger>

              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-disabled">visit us on Telegram</Tooltip>
                }
              >
                <span className="d-inline-block">
                  <a
                    href="https://t.me/shib_ai_nu"
                    className="text-white btn btn-link aisLinkColor"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i class="bi bi-telegram"></i>
                  </a>
                </span>
              </OverlayTrigger>

              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-disabled">visit us on Instagram</Tooltip>
                }
              >
                <span className="d-inline-block">
                  <a
                    href="https://instagram.com/shib_ai_nu"
                    className="text-white btn btn-link aisLinkColor"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i class="bi bi-instagram"></i>
                  </a>
                </span>
              </OverlayTrigger>

              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-disabled">visit us on Reddit</Tooltip>
                }
              >
                <span className="d-inline-block">
                  <a
                    href="https://www.reddit.com/user/shib_ai_nu"
                    className="text-white btn btn-link aisLinkColor"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i class="bi bi-reddit"></i>
                  </a>
                </span>
              </OverlayTrigger>

              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-disabled">visit us on Facebook</Tooltip>
                }
              >
                <span className="d-inline-block">
                  <a
                    href="https://www.facebook.com/shibainuAi/"
                    className="text-white btn btn-link aisLinkColor"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i class="bi bi-facebook"></i>
                  </a>
                </span>
              </OverlayTrigger>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="p-4">
              © {currentYear} shib.ai.nu All Rights Reserved.
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default FooterComponent;
