import React from "react";
import aiscubesCreations from "../../images/aiscubes-creation.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import Image from "react-bootstrap/Image";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function AiscubesComponent() {
  return (
    <>
      <Container>
        <Row>
          <Col xs="12">
            <div className="p-4">
              <h1 className="h2 mt-5">
                <span className="aisColors">Aiscubes</span>{" "}
                <small className="text-body-secondary">
                  {" "}
                  boost your thoughts
                </small>
              </h1>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="p-4">
              <span className="aisColors">Aiscubes</span> will be{" "}
              <span className="aisColors">shib.ai.nu's </span>
              first project to be sponsored by the{" "}
              <span className="aisColors">$shai</span> token. Our common goal
              should be to develop many projects that build on each other in
              order to offer our community financial independence. We will do
              everything so that we all have financial advantages, even in a
              bear market.
            </div>
          </Col>
        </Row>
        <Row className="g-4">
          <Col xs="12" md="6">
            <div className="p-4">
              <Card>
                <Card.Body className="aiscubesAccordionHeight">
                  <Card.Text>
                    <Accordion defaultActiveKey="0" flush>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>1. navigation</Accordion.Header>
                        <Accordion.Body>
                          <strong>creations:</strong>
                          <br />
                          <p>
                            in the creations bar you can let your inspiration
                            run wild.
                          </p>
                          <strong>marketplace:</strong>
                          <br />
                          <p>
                            create unique designs and post them in our
                            marketplace immediately.
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          2. login and register
                        </Accordion.Header>
                        <Accordion.Body>
                          Login and register with your wallet or email
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>
                          3. creation tool bar
                        </Accordion.Header>
                        <Accordion.Body>
                          do you want to generate a unique text or an
                          ai-generated image for your website? no problem with
                          the aiscubes ai engine. We will also be able to offer
                          videos and audios in the future.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                        <Accordion.Header>4. enter your text</Accordion.Header>
                        <Accordion.Body>
                          Here you can enter a text command that will generate
                          data into a desired image or modified text within a
                          few seconds
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="5">
                        <Accordion.Header>5. engine toolbar</Accordion.Header>
                        <Accordion.Body>
                          <i class="bi bi-tools"></i> modify your text, if it is
                          necessary
                          <br />
                          <i class="bi bi-gear"></i> create an example, if you
                          want
                          <br />
                          <i class="bi bi-arrow-clockwise"></i> generate your
                          art, if you are happy with the result
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="6">
                        <Accordion.Header>6. get your results</Accordion.Header>
                        <Accordion.Body>
                          get your unique results. In the future you will be
                          able to choose the number of results{" "}
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="7">
                        <Accordion.Header>7. results toolbar</Accordion.Header>
                        <Accordion.Body>
                          <i class="bi bi-plus-square-fill"></i> add to
                          marketplace, to earn passive income and show the world
                          how creative you are
                          <br />
                          <i class="bi bi-arrow-clockwise"></i> you are already
                          very satisfied with the result, but still want to see
                          more variations
                          <br />
                          <i class="bi bi-cloud-arrow-down-fill"></i> if you
                          just want to download your result, you can do it here
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </Col>
          <Col xs="12" md="6">
            <div className="p-4">
              <Image src={aiscubesCreations} fluid />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <div className="p-4">
              <h1 className="h2 mt-5">
                FInd <span className="aisColors">Aiscubes</span>{" "}
                <small className="text-body-secondary">on social media</small>
              </h1>

              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-disabled">
                    visit our aiscubes website
                  </Tooltip>
                }
              >
                <span className="d-inline-block">
                  <a
                    href="https://www.aiscubes.com"
                    className="text-white btn btn-link aisLinkColor"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i class="bi bi-globe-europe-africa biggerIcons"></i>
                  </a>
                </span>
              </OverlayTrigger>

              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-disabled">visit us on Twitter</Tooltip>
                }
              >
                <span className="d-inline-block">
                  <a
                    href="https://twitter.com/_aiscubes"
                    className="text-white btn btn-link aisLinkColor"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i class="bi bi-twitter biggerIcons"></i>
                  </a>
                </span>
              </OverlayTrigger>

              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-disabled">visit us on Telegram</Tooltip>
                }
              >
                <span className="d-inline-block">
                  <a
                    href="https://t.me/aiscubes"
                    className="text-white btn btn-link aisLinkColor"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i class="bi bi-telegram biggerIcons"></i>
                  </a>
                </span>
              </OverlayTrigger>

              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-disabled">visit us on Instagram</Tooltip>
                }
              >
                <span className="d-inline-block">
                  <a
                    href="https://instagram.com/_aiscubes"
                    className="text-white btn btn-link aisLinkColor"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i class="bi bi-instagram biggerIcons"></i>
                  </a>
                </span>
              </OverlayTrigger>

              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-disabled">visit us on Reddit</Tooltip>
                }
              >
                <span className="d-inline-block">
                  <a
                    href="https://www.reddit.com/user/aiscubes"
                    className="text-white btn btn-link aisLinkColor"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i class="bi bi-reddit biggerIcons"></i>
                  </a>
                </span>
              </OverlayTrigger>

              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-disabled">visit us on Facebook</Tooltip>
                }
              >
                <span className="d-inline-block">
                  <a
                    href="https://www.facebook.com/aiscubes/"
                    className="text-white btn btn-link aisLinkColor"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i class="bi bi-facebook biggerIcons"></i>
                  </a>
                </span>
              </OverlayTrigger>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AiscubesComponent;
