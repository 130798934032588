import React, { useState } from "react";
import Card from "react-bootstrap/Card";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Toast from "react-bootstrap/Toast";
import { useClipboard } from "use-clipboard-copy";
import banner from "../../images/shib.ai.nu-header.png";
import Webby from "../../services/web3/webby.js";
import Timer from "../../services/web3/timer.js";

function HeaderComponent() {
  const [show, setShow] = useState(false);

  const clipboardContract = useClipboard({
    onSuccess() {
      setShow(true);
    },
    onError() {
      console.log("Failed to copy text!");
    },
  });

  const clipboardPublic = useClipboard({
    onSuccess() {
      setShow(true);
    },
    onError() {
      console.log("Failed to copy text!");
    },
  });

  return (
    <>
      <header>
        <Card className=" rounded-0 mt-Minus100">
          <Card.Img src={banner} alt="Card image" />
          <Card.ImgOverlay id="overlayWalletBox">
            <Card.Title className="h1">
              {/*<span className="aisColors">$SHAI</span> get your Presale
              Tokens*/}
              <span className="aisColors">$SHAI</span> presale starts in:
            </Card.Title>

            <Card.Text className="walletBoxText">
              <Timer />
              <Row>
                <Col xs={12}>
                  <div className="pt-4">
                    <h6 className="hLines">
                      <span>
                        We start on Saturday the 19th of April 2023 at 00:00 CET
                      </span>
                    </h6>
                  </div>
                </Col>
                <Col style={{ textAlign: "center" }}>
                  <span className="aisColors" style={{ fontSize: "3rem" }}>
                    the power of meme token
                  </span>
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <div className="pt-4">
                    <h6 className="hLines">
                      <span>
                        1 <span className="aisColors">$SHAI</span>: ~{" "}
                        <span className="aisColors">$</span>0.000025 / ~{" "}
                        <span className="aisColors">ETH</span>0.000000013
                      </span>
                    </h6>
                  </div>
                  <div className="pt-4">
                    <h6 className="hLines">
                      {" "}
                      <span>
                        Listing price <span className="aisColors">$</span>
                        0.00004 / ~ <span className="aisColors">ETH</span>
                        0.000000021{" "}
                      </span>
                    </h6>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <div className="pt-1 pb-1">
                    <h6>
                      0.002 <span className="aisColors">ETH</span> is reserved
                      for gas. The actual amount used will depend on the
                      network.
                    </h6>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <Toast
                    onClose={() => setShow(false)}
                    show={show}
                    delay={5000}
                    autohide
                    bg="success"
                    className=""
                  >
                    <Toast.Header>
                      <img
                        src="holder.js/20x20?text=%20"
                        className="rounded me-2"
                        alt=""
                      />
                      <strong className="me-auto">$Shai</strong>
                    </Toast.Header>
                    <Toast.Body>successfully copied!</Toast.Body>
                  </Toast>
                </Col>
              </Row>
            </Card.Text>
          </Card.ImgOverlay>
        </Card>
      </header>
    </>
  );
}

export default HeaderComponent;
