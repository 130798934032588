import React from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import faq from "../../images/shib.ai.nu-server-rack.png";

function RewardsComponent() {
  return (
    <>
      <Container>
        <Row>
          <Col>
            <div className="p-4">
              <h1 className="h2 mt-5">
                <span className="aisColors">Shib.AI.nu</span>{" "}
                <small className="text-body-secondary">
                  {" "}
                  billions of token for you
                </small>
              </h1>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <div className="p-5">
              <img src={faq} alt="ais faq image" className="img-fluid" />
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="p-5">
              <p>
                Staking <span className="aisColors">Shib.AI.nu</span> tokens is
                a popular way for cryptocurrency holders to earn passive income
                while supporting the network.{" "}
                <span className="aisColors">Shib.AI.nu</span> is also a
                decentralized finance (DeFi) project that aims to create a more
                equitable and transparent financial system using blockchain
                technology.
              </p>
              <p>
                When you stake <span className="aisColors">Shib.AI.nu</span>{" "}
                tokens, you are essentially locking them up in a smart contract
                for a certain period of time. In return, you earn a percentage
                of the network's transaction fees and rewards for validating
                transactions. The longer you stake your tokens, the higher your
                rewards will be.
              </p>
              <p>
                One of the main advantages of staking{" "}
                <span className="aisColors">Shib.AI.nu</span> tokens is that it
                incentivizes holders to keep their tokens in the network, which
                helps to increase the overall security and stability of the
                blockchain. This is because the more tokens that are staked, the
                more difficult it becomes for malicious actors to attack the
                network.
              </p>
              <p>
                Another advantage of staking{" "}
                <span className="aisColors">Shib.AI.nu</span> tokens is that it
                can help to reduce price volatility. When a large percentage of
                tokens are being staked, there are fewer tokens available for
                trading on exchanges, which can help to stabilize the price.
                Additionally, by staking your tokens, you are not selling them
                on the market, which can help to prevent price drops caused by
                panic selling.
              </p>
              <p>
                Overall, staking <span className="aisColors">Shib.AI.nu</span>{" "}
                tokens is a great way to earn passive income while contributing
                to the security and stability of the network. If you're
                interested in staking{" "}
                <span className="aisColors">Shib.AI.nu</span> tokens, be sure to
                do your research and choose a reputable staking service that
                offers competitive rewards and low fees.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default RewardsComponent;
