import React, { useState, useEffect, useLayoutEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Scrollspy from "react-scrollspy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import TokenomicsComponent from "../TokenomicsComponent/TokenomicsComponent";
import RoadmapComponent from "../RoadmapComponent/RoadmapComponent";
import { Link, NavLink } from "react-router-dom";

function WhitepaperComponent() {
  const [show, setShow] = useState(
    JSON.parse(localStorage.getItem("open")) || false
  );

  useEffect(() => {
    if (window.location.href.indexOf("WhitePaper") > -1) {
      handleOpen();
    }
  }, []);

  const handleOpen = () => {
    setShow((prevState) => {
      window.history.pushState(null, null, " ");
      localStorage.setItem("open", !prevState);
      return !prevState;
    });
  };

  const Print = () => {
    var DocumentContainer = document.getElementById("printWhitepaper");
    var WindowObject = window.open(
      "",
      "PrintWindow",
      "width=750,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes"
    );
    WindowObject.document.writeln(DocumentContainer.innerHTML);
    WindowObject.document.close();
    setTimeout(function () {
      WindowObject.focus();
      WindowObject.print();
      WindowObject.close();
    }, 6000);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Scrollspy
        items={[
          "WhitePaperIntroducing",
          "WhitePaperTheProjects",
          "WhitePaperWhatIsShibAiNu",
          "WhitePaperWhatIsShai",
          "WhitePaperWhatIsAisCubes",
          "WhitePaperTheToken",
          "WhitePaperTokenomics",
          "WhitePaperTokenDistribution",
          "WhitePaperForOurCommunity",
          "WhitePaperAirdrops",
          "WhitePaperParticipation",
          "WhitePaperOurGoals",
          "WhitePaperRoadmap",
          "WhitePaperGetInvolved",
          "WhitePaperSummary",
          "WhitePaperBackToTopButton",
        ]}
        currentClassName="is-current"
      ></Scrollspy>
      <a
        id="whitepaperModal"
        variant="primary"
        onClick={handleOpen}
        size="lg"
        className="shibainuButton btn"
        href="#WhitePaperHome"
        type="button"
      >
        <i class="bi bi-file-earmark-text"></i> whitepaper
      </a>

      <Modal
        show={show}
        onHide={handleOpen}
        dialogClassName="modal-100vw modal-100vh"
        aria-labelledby="example-custom-modal-styling-title"
        fullscreen
        id="printWhitepaper"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="aisColors"> SHIB.AI.NU</span> Whitepaper
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="d-flex" id="WhitePaperBackToTopButton">
              <Col>
                <h1 className="h4 text-uppercase mt-3">Table of contents</h1>

                <div className="toc">
                  <table>
                    <tr>
                      <td>1. </td>
                      <td>
                        <a
                          href="#WhitePaperIntroducing"
                          className="text-white aisLinkColor"
                        >
                          Introducing
                        </a>
                      </td>
                      <td>page 01</td>
                    </tr>
                    <tr>
                      <td>2. </td>
                      <td>
                        {" "}
                        <a
                          href="#WhitePaperTheProjects"
                          className="text-white aisLinkColor"
                        >
                          The Project
                        </a>
                      </td>
                      <td>page 02</td>
                    </tr>
                    <tr>
                      <table className="indent">
                        <tr>
                          <td>2.1 </td>
                          <td>
                            <a
                              href="#WhitePaperWhatIsShibAiNu"
                              className="text-white aisLinkColor"
                            >
                              What is
                              <span className="aisColors"> SHIB.AI.NU</span>
                            </a>
                          </td>
                          <td>page 2.1</td>
                        </tr>
                        <tr>
                          <td>2.2 </td>
                          <td>
                            <a
                              href="#WhitePaperWhatIsShai"
                              className="text-white aisLinkColor"
                            >
                              What is
                              <span className="aisColors"> $SHAI</span>
                            </a>
                          </td>
                          <td>page 2.2</td>
                        </tr>
                        <tr>
                          <td>2.3 </td>
                          <td>
                            <a
                              href="#WhitePaperWhatIsAisCubes"
                              className="text-white aisLinkColor"
                            >
                              What is
                              <span className="aisColors"> AISCUBES</span>
                            </a>
                          </td>
                          <td>page 2.3</td>
                        </tr>
                      </table>
                    </tr>
                    <tr>
                      <td>3. </td>
                      <td>
                        {" "}
                        <a
                          href="#WhitePaperTheToken"
                          className="text-white aisLinkColor"
                        >
                          The Token
                        </a>{" "}
                      </td>
                      <td>page 03</td>
                    </tr>
                    <tr>
                      <table className="indent">
                        <tr>
                          <td>3.1 </td>
                          <td>
                            {" "}
                            <a
                              href="#WhitePaperTokenomics"
                              className="text-white aisLinkColor"
                            >
                              Tokenomics
                            </a>{" "}
                          </td>
                          <td>page 3.1</td>
                        </tr>
                        <tr>
                          <td>3.2 </td>
                          <td>
                            {" "}
                            <a
                              href="#WhitePaperTokenDistribution"
                              className="text-white aisLinkColor"
                            >
                              Token Distribution
                            </a>{" "}
                          </td>
                          <td>page 3.2</td>
                        </tr>
                      </table>
                    </tr>
                    <tr>
                      <td>4. </td>
                      <td>
                        {" "}
                        <a
                          href="#WhitePaperForOurCommunity"
                          className="text-white aisLinkColor"
                        >
                          For our community
                        </a>{" "}
                      </td>
                      <td>page 04</td>
                    </tr>
                    <tr>
                      <table className="indent">
                        <tr>
                          <td>4.1 </td>
                          <td>
                            {" "}
                            <a
                              href="#WhitePaperAirdrops"
                              className="text-white aisLinkColor"
                            >
                              Airdrops
                            </a>{" "}
                          </td>
                          <td>page 4.1</td>
                        </tr>
                        <tr>
                          <td>4.2 </td>
                          <td>
                            {" "}
                            <a
                              href="#WhitePaperParticipation"
                              className="text-white aisLinkColor"
                            >
                              Participation
                            </a>{" "}
                          </td>
                          <td>page 4.2</td>
                        </tr>
                        <tr>
                          <td>4.3 </td>
                          <td>
                            {" "}
                            <a
                              href="#WhitePaperOurGoals"
                              className="text-white aisLinkColor"
                            >
                              Our goals
                            </a>{" "}
                          </td>
                          <td>page 4.3</td>
                        </tr>
                      </table>
                    </tr>

                    <tr>
                      <td>5. </td>
                      <td>
                        {" "}
                        <a
                          href="#WhitePaperRoadmap"
                          className="text-white aisLinkColor"
                        >
                          Roadmap
                        </a>{" "}
                      </td>
                      <td>page 05</td>
                    </tr>
                    <tr>
                      <td>6. </td>
                      <td>
                        {" "}
                        <a
                          href="#WhitePaperGetInvolved"
                          className="text-white aisLinkColor"
                        >
                          Get involved
                        </a>
                      </td>
                      <td>page 06</td>
                    </tr>
                    <tr>
                      <td>7. </td>
                      <td>
                        {" "}
                        <a
                          href="#WhitePaperSummary"
                          className="text-white aisLinkColor"
                        >
                          Summary
                        </a>
                      </td>
                      <td>page 07</td>
                    </tr>
                  </table>
                </div>
              </Col>
            </Row>
            <hr />
            <Row className="d-flex" id="WhitePaperIntroducing">
              <Col>
                <h1 className="h4 text-uppercase mt-3">1. INTRODUCING</h1>
                <p>
                  Welcome to <span className="aisColors"> SHIB.AI.NU</span>!
                  Imagine there would be a marketplace where you could generate
                  AI-generated texts, art, social media posts, books and much
                  more for free and also offer sales. It does not work? But with
                  the <span className="aisColors"> aiscubes</span> project.
                </p>
                <p>
                  Let's explain our idea and how our investors and sellers can
                  build significant passive income. Join us on a new Crytpo
                  journey through time and believe in something big and new! We
                  make it come true!
                </p>
                <p>
                  We bring the AI and the crypto world together to create an
                  unbeatable team. Don't miss this unique opportunity
                </p>
                <div className="float-end">
                  <h4>
                    <span class="badge bg-secondary">01</span>
                  </h4>
                </div>
              </Col>
            </Row>

            <hr />

            <Row className="d-flex" id="WhitePaperTheProjects">
              <Col>
                <h1 className="h4 text-uppercase mt-3">2. THE PROJECTS</h1>
                <p>
                  Our projects are based on three pillars, 1. the
                  <span className="aisColors"> SHIB.AI.NU</span>
                  project, 2. the <span className="aisColors"> $shai</span>
                  token and 3. the
                  <span className="aisColors"> aiscubes</span> project. These
                  three pillars interlock seamlessly and guarantee a smooth
                  process in the
                  <span className="aisColors"> SHIB.AI.NU</span> ecosystem.
                </p>
                <h1
                  className="h4 text-uppercase mt-3"
                  id="WhitePaperWhatIsShibAiNu"
                >
                  2.1 WHAT IS <span className="aisColors"> SHIB.AI.NU</span>?
                </h1>
                <p>
                  <span className="aisColors"> SHIB.AI.NU</span> is practically
                  the main project of our endeavor. Everything that will be
                  brought to life in future projects will run via
                  <span className="aisColors"> SHIB.AI.NU</span>. We will also
                  be launching other highly interesting projects in the future
                  that we would like to share with our community.
                </p>
                <h1
                  className="h4 text-uppercase mt-3"
                  id="WhitePaperWhatIsShai"
                >
                  2.2 WHAT IS <span className="aisColors"> $SHAI</span>?
                </h1>
                <p>
                  The <span className="aisColors"> $shai</span> token is the
                  next pillar to finance our joint projects. It is a
                  deflationary ERC20 token that aims to be 100% owned by the
                  community. More on that in Tokenomics and Token Distribution
                </p>

                <h1
                  className="h4 text-uppercase mt-3"
                  id="WhitePaperWhatIsAisCubes"
                >
                  2.3 WHAT IS <span className="aisColors"> AISCUBES</span>?
                </h1>
                <p>
                  <span className="aisColors"> aiscubes</span> will map the
                  complete project and be completely independent of
                  <span className="aisColors"> SHIB.AI.NU</span> and
                  <span className="aisColors"> $SHAI</span>.
                  <span className="aisColors"> aiscubes</span> will regularly
                  flush new money into the
                  <span className="aisColors"> SHIB.AI.NU</span> project and
                  thus guarantee a regular flow in our ecosystem. Fees /
                  commissions arise from the
                  <span className="aisColors"> aiscubes</span> sales, we share
                  30% of these with our holders.
                </p>
                <div className="float-end">
                  <h4>
                    <span class="badge bg-secondary">02</span>
                  </h4>
                </div>
              </Col>
            </Row>

            <hr />

            <Row className="d-flex" id="WhitePaperTheToken">
              <Col>
                <h1 className="h4 text-uppercase mt-3">3. The Tokens</h1>
                <p>
                  We create an ERC20 token and later also a BEP20 token. The
                  token is deflationary and burnable. more information about
                  this in our token distribution.
                </p>
                <h1
                  className="h4 text-uppercase mt-3"
                  id="WhitePaperTokenomics"
                >
                  3.1 TOKENOMICS
                </h1>
                <p>
                  We are making 30% of the
                  <span className="aisColors"> $shai</span> token available for
                  presale at a unit price of just $0.000025 per
                  <span className="aisColors"> $shai</span> token. This equates
                  to an approximate amount of $7,500,000. If not all tokens are
                  sold, they will burn after the time frame has expired.
                </p>
                <p>
                  We provide 10% at the beginning for the implementation of the
                  project. From this we expand our team with new developers,
                  marketing experts and advertising. These 10% should gradually
                  benefit the community.
                </p>
                <p>
                  60% of the <span className="aisColors"> $shai</span> token
                  will be made available on DEX and CEX. In this way we ensure
                  that we can bring new investors and a fresh breeze into the
                  project.
                </p>
                <p>
                  At the end of the burning process, only 500,000,000
                  <span className="aisColors"> $shai</span> tokens should be
                  left in circulation.
                </p>

                <TokenomicsComponent />

                <h1
                  className="h4 text-uppercase mt-3"
                  id="WhitePaperTokenDistribution"
                >
                  3.2 TOKEN DISTRIBUTION
                </h1>
                <p>
                  As said before, the
                  <span className="aisColors"> $shai</span> token is a
                  deflationary ERC20 token. 2% of
                  <span className="aisColors"> $shai</span> tokens are burned
                  regularly, another 2% are provided for the team so that we can
                  cover the costs. In addition, 2% is deposited with Uniswap for
                  liquidity.
                </p>
                <div className="float-end">
                  <h4>
                    <span class="badge bg-secondary">03</span>
                  </h4>
                </div>
              </Col>
            </Row>

            <hr />

            <Row className="d-flex" id="WhitePaperForOurCommunity">
              <Col>
                <h1 className="h4 text-uppercase mt-3">4. For our community</h1>
                <p>
                  Our community is very important to us and is very important to
                  us. We give everything for our loyal investors and know
                  exactly what they have made possible for us. We will never
                  disappoint you and keep everything as transparent as possible.
                </p>
                <h1 className="h4 text-uppercase mt-3" id="WhitePaperAirdrops">
                  4.1 Airdrops
                </h1>
                <p>
                  Every holder should be able to enjoy airdrops, i.e. we will
                  regularly send a certain number of tokens to our loyal
                  community. We'll rack our brains about the height, which
                  unfortunately isn't certain at this point in time.
                </p>
                <h1
                  className="h4 text-uppercase mt-3"
                  id="WhitePaperParticipation"
                >
                  4.2 Participation
                </h1>
                <p>
                  Sales are generated via our marketplace and commissions are
                  due as soon as an artist buys or sells something. We will
                  regularly give our holders a 30% share of these commissions in
                  the form of distributions. A monthly distribution is planned.
                  The other 70% is used for the project and flows into new
                  ideas, so that we will never stand still.
                </p>

                <h1 className="h4 text-uppercase mt-3" id="WhitePaperOurGoals">
                  4.3 Our goals
                </h1>
                <p>
                  Our goal will be that in the end the community will have 100%
                  of the tokens and in the end we will only finance ourselves
                  through the fees and the marketplace. This prevents internal
                  trading with tokens, which we simply do not want. Something
                  like that creates a bad atmosphere in the community and we
                  don't want that. But it must also be clear that we have to
                  finance this project somehow so that we can achieve our goals
                  together. Our community has an important voice! We will
                  prepare polls for all important actions so that everyone has a
                  chance to express their opinion. This project should be 100%
                  community driven! But that won't happen overnight, give us
                  some time.
                </p>
                <div className="float-end">
                  <h4>
                    <span class="badge bg-secondary">04</span>
                  </h4>
                </div>
              </Col>
            </Row>

            <hr />

            <Row className="d-flex" id="WhitePaperRoadmap">
              <Col className="vh-300">
                <RoadmapComponent />

                <div className="float-end">
                  <h4>
                    <span class="badge bg-secondary">05</span>
                  </h4>
                </div>
              </Col>
            </Row>

            <hr />

            <Row className="d-flex" id="WhitePaperGetInvolved">
              <Col>
                <h1 className="h4 text-uppercase mt-3">6. Get involved</h1>

                <p>
                  Visit us on our social media channels and stay informed about
                  our current status and news. We present all innovations on our
                  social media. You are also welcome to contact us via our
                  social media. We are looking forward to your contact.
                </p>
                <p>
                  we will soon expand our social media for even more
                  accessibility
                </p>

                <div className="p-4">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        visit us on Twitter
                      </Tooltip>
                    }
                  >
                    <span className="d-inline-block">
                      <a
                        href="https://twitter.com/shib_ai_nu"
                        className="text-white btn btn-link aisLinkColor"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i class="bi bi-twitter whitepaperIconSize"></i>
                      </a>
                    </span>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        visit us on Telegram
                      </Tooltip>
                    }
                  >
                    <span className="d-inline-block">
                      <a
                        href="https://t.me/shib_ai_nu"
                        className="text-white btn btn-link aisLinkColor"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i class="bi bi-telegram whitepaperIconSize"></i>
                      </a>
                    </span>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        visit us on Instagram
                      </Tooltip>
                    }
                  >
                    <span className="d-inline-block">
                      <a
                        href="https://instagram.com/shib_ai_nu"
                        className="text-white btn btn-link aisLinkColor"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i class="bi bi-instagram whitepaperIconSize"></i>
                      </a>
                    </span>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        visit us on Reddit
                      </Tooltip>
                    }
                  >
                    <span className="d-inline-block">
                      <a
                        href="https://www.reddit.com/user/shib_ai_nu"
                        className="text-white btn btn-link aisLinkColor"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i class="bi bi-reddit whitepaperIconSize"></i>
                      </a>
                    </span>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        visit us on Facebook
                      </Tooltip>
                    }
                  >
                    <span className="d-inline-block">
                      <a
                        href="https://www.facebook.com/shibainuAi/"
                        className="text-white btn btn-link aisLinkColor"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i class="bi bi-facebook whitepaperIconSize"></i>
                      </a>
                    </span>
                  </OverlayTrigger>
                </div>

                <div className="float-end">
                  <h4>
                    <span class="badge bg-secondary">06</span>
                  </h4>
                </div>
              </Col>
            </Row>

            <hr />

            <Row className="d-flex" id="WhitePaperSummary">
              <Col>
                <h1 className="h4 text-uppercase mt-3">7. Summary</h1>

                <strong>Here is a brief summary of our whitepaper:</strong>
                <ul>
                  <tr>
                    - We offer an easy and cheap start into the
                    <span className="aisColors"> SHIB.AI.NU</span> project
                  </tr>
                  <tr>
                    - Every investor immediately receives their
                    <span className="aisColors"> $shai</span> tokens in their
                    Metamask wallet after purchase. No claiming fees.
                  </tr>
                  <tr> - The presale is reduced to 300,000,000,000 tokens</tr>
                  <tr> - Unsold presale tokens will be burned</tr>
                  <tr> - Our holders regularly receive airdrops</tr>
                  <tr>
                    - We share our investors in the commissions and fees of the
                    <span className="aisColors"> aiscubes</span> sales
                  </tr>
                  <tr>
                    - We make everything transparent with the agreement of the
                    community
                  </tr>
                  <tr>
                    - All tokens should ultimately belong 100% to the community
                  </tr>
                  <tr>- Marketing strategies will be developed regularly</tr>
                  <tr>
                    - the AI of <span className="aisColors"> aiscubes </span>
                    should be 100% free
                  </tr>
                  <tr>
                    - Tokens are burned regularly. Our goal should be to end up
                    with only 500,000,000 tokens in circulation
                  </tr>
                  <tr>
                    - <span className="aisColors"> aiscubes</span> will always
                    flush new capital into the token
                  </tr>
                </ul>
                <div className="float-end">
                  <h4>
                    <span class="badge bg-secondary">07</span>
                  </h4>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <h6 className="py-2 float-start">
            <span className="aisColors"> SHIB.AI.NU</span> whitepaper version
            1.0 / 06/25/2023
          </h6>

          <div className="float-end">
            <a
              className="text-white aisLinkColor m-3"
              type="button"
              onClick={() => Print()}
            >
              <i className="bi bi-printer-fill"></i>
            </a>

            <a
              href="#WhitePaperBackToTopButton"
              className="text-white aisLinkColor"
            >
              <i className="bi bi-arrow-up-circle"></i>
            </a>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default WhitepaperComponent;
