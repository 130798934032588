import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import parse from "html-react-parser";

function RoadmapComponent() {
  const reviews = [
    {
      _id: 1,
      cardTitle1: "Q1 2023",
      cardTitle2: "Q2 2023",
      cardTitle3: "Q3 2023",
      cardTitle4: "Q4 2023",
      cardText1:
        "<li>concept creation</li><li>creation of an overarching marketing strategy</li><li>concept validation</li>",
      cardText2:
        '<li>website and whitepaper creation</li><li>promote <span className="aisColors">$SHAI</span> on social media</li><li>creating and deploying ERC20 <span className="aisColors">$SHAI</span> token</li><li>presale starts</li>',
      cardText3:
        '<li>designing of <span className="aisColors">$SHAI</span> platform starts</li><li>presale ends</li><li>burning the none sold <span className="aisColors">$SHAI</span> token from presale</li><li>listings <span className="aisColors">$SHAI</span> on famous DEX</li>',
      cardText4:
        "<li>Listings on CoinMarketCap and CoinGecko</li><li>Community votes, events and competitions</li><li>Huge Marketing Push</li><li>adding staking functionalities</li>",
    },

    {
      _id: 2,
      cardTitle1: "Q1 2024",
      cardTitle2: "Q2 2024",
      cardTitle3: "Q3 2024",
      cardTitle4: "Q4 2024",
      cardText1:
        '<li>start developing <span className="aisColors">Shib.ai.nu</span> platform </li><li>designing the platform functionalities</li><li>beginning to implement the <span className="aisColors"></span> and funtionalities</li>',
      cardText2:
        '<li>continue developing <span className="aisColors">Shib.ai.nu</span> platform and the individual functionalities</li><li>Coding automated test and bug fixing</li><li>start with suitable partnerships</li>',
      cardText3:
        '<li>presenting <span className="aisColors">Shib.ai.nu</span> platform beta</li><li>Start coding the <span className="aisColors">Shib.ai.nu</span> marketplace for art</li><li>rewards for the best voted arts</li></li>',
      cardText4:
        '<li>Finish our work for <span className="aisColors">Shib.ai.nu</span> version 1</li><li>sell your <span className="aisColors">AI</span> art at our <span className="aisColors">Shib.ai.nu</span>marketplace</li><li>30% of our provision sells from  <span className="aisColors">Shib.ai.nu</span> marketplace are for our lovely community</li><li>make much more huge marketing</li><li>preparing for 2025</li>',
    },
  ];

  return (
    <>
      <Container>
        <Row className="d-flex" id="WhitePaperRoadmap">
          <Col className="vh-300">
            <h1 className="h2 mt-5">
              <span className="aisColors">Shib.AI.nu </span>
              <small className="text-body-secondary">
                our roadmap to generation
              </small>
            </h1>

            <div className="timeline">
              <div className="timeline-row">
                <div className="timeline-time">q1 2023</div>
                <div className="timeline-content text-end">
                  <h4 className="text-end">Starting the idea</h4>
                  <p>
                    <ul className="list-unstyled">
                      <li>concept creation -</li>
                      <li>creation of an overarching marketing strategy -</li>
                      <li>concept validation -</li>
                    </ul>
                  </p>

                  <div className="">
                    <span class="badge rounded-pill bg-success">idea</span>
                    <span class="badge rounded-pill bg-success">concept</span>
                    <span class="badge rounded-pill bg-success">
                      brain storming
                    </span>
                  </div>
                </div>
              </div>

              <div className="timeline-row">
                <div className="timeline-time">Q2 2023</div>
                <div className="timeline-content">
                  <h4>starting development</h4>
                  <p>
                    <ul className="list-unstyled">
                      <li>- website and whitepaper creation</li>
                      <li>
                        - promote <span className="aisColors"> $SHAI </span> on
                        social media
                      </li>
                      <li>
                        - creating and deploying ERC20{" "}
                        <span className="aisColors"> $SHAI </span> token
                      </li>
                    </ul>
                  </p>

                  <div className="">
                    <span class="badge rounded-pill bg-success">
                      whitepaper
                    </span>
                    <span class="badge rounded-pill bg-success">
                      social media
                    </span>
                    <span class="badge rounded-pill bg-success">promotion</span>
                  </div>
                </div>
              </div>

              <div className="timeline-row">
                <div className="timeline-time">Q3 2023</div>
                <div className="timeline-content text-end">
                  <h4 className="text-end">Starting the project</h4>
                  <p>
                    <ul className="list-unstyled">
                      <li>presale starts -</li>
                      <li>
                        designing of{" "}
                        <span className="aisColors"> AISCUBES </span> platform
                        starts -
                      </li>
                      <li>presale ends -</li>
                      <li>
                        burning the none sold{" "}
                        <span className="aisColors"> $SHAI </span> token from
                        presale -
                      </li>
                      <li>
                        listings <span className="aisColors"> $SHAI </span> on
                        famous DEX -
                      </li>
                    </ul>
                  </p>
                  <div>
                    <span class="badge rounded-pill bg-success">designing</span>
                    <span class="badge rounded-pill bg-success">burning</span>
                    <span class="badge rounded-pill bg-success">$SHAI</span>
                    <span class="badge rounded-pill bg-success">DEX / CEX</span>
                    <span class="badge rounded-pill bg-success">AISCUBES</span>
                  </div>
                </div>
              </div>

              <div className="timeline-row">
                <div className="timeline-time">Q4 2023</div>
                <div className="timeline-content">
                  <h4>Doing marketing</h4>
                  <p>
                    <ul className="list-unstyled">
                      <li>- Listings on CoinMarketCap and CoinGecko</li>
                      <li>- Community votes, events and competitions</li>
                      <li>- Huge Marketing Push</li>
                      <li>- adding staking functionalities</li>
                    </ul>
                  </p>
                  <div>
                    <span class="badge rounded-pill bg-success">
                      CoinMarketCap / Coingecko
                    </span>
                    <span class="badge rounded-pill bg-success">Events</span>
                    <span class="badge rounded-pill bg-success">$SHAI</span>
                    <span class="badge rounded-pill bg-success">Marketing</span>
                    <span class="badge rounded-pill bg-success">Staking</span>
                  </div>
                </div>
              </div>

              <div className="timeline-row">
                <div className="timeline-time">Q1 2024</div>
                <div className="timeline-content text-end">
                  <h4 className="text-end">
                    Starting with <span className="aisColors"> AISCUBES </span>
                  </h4>
                  <p className="no-margin">
                    <ul className="list-unstyled">
                      <li>
                        start developing
                        <span className="aisColors"> AISCUBES </span> platform -
                      </li>
                      <li>designing the platform functionalities -</li>
                      <li>beginning to implement the funtionalities -</li>
                      <li>at the same time we are planning a mobile app -</li>
                    </ul>
                  </p>
                  <div>
                    <span class="badge rounded-pill bg-success">
                      Developing
                    </span>
                    <span class="badge rounded-pill bg-success">
                      functionalities
                    </span>
                  </div>
                </div>
              </div>

              <div className="timeline-row">
                <div className="timeline-time">Q2 2024</div>
                <div className="timeline-content">
                  <h4>starting with partnerships</h4>
                  <p>
                    <ul className="list-unstyled">
                      <li>
                        - continue developing{" "}
                        <span className="aisColors"> AISCUBES </span> platform
                        and the individual functionalities
                      </li>
                      <li>- Coding automated test and bug fixing</li>
                      <li>- start with suitable partnerships</li>
                    </ul>
                  </p>
                  <div className="">
                    <span class="badge rounded-pill bg-success">
                      partnerships
                    </span>
                    <span class="badge rounded-pill bg-success">coding</span>
                  </div>
                </div>
              </div>

              <div className="timeline-row">
                <div className="timeline-time">Q3 2024</div>
                <div className="timeline-content text-end">
                  <h4 className="text-end">presenting beta</h4>
                  <p>
                    <ul className="list-unstyled">
                      <li>
                        presenting <span className="aisColors"> AISCUBES </span>{" "}
                        platform beta -
                      </li>
                      <li>
                        Start coding the{" "}
                        <span className="aisColors"> AISCUBES </span>{" "}
                        marketplace for art -
                      </li>
                      <li>rewards for the best voted arts -</li>
                    </ul>
                  </p>
                  <div className="">
                    <span class="badge rounded-pill bg-success">
                      marketplace
                    </span>
                    <span class="badge rounded-pill bg-success">rewards</span>
                    <span class="badge rounded-pill bg-success">airdrops</span>
                  </div>
                </div>
              </div>

              <div className="timeline-row">
                <div className="timeline-time">Q4 2024</div>
                <div className="timeline-content">
                  <h4>preparing for 2025</h4>
                  <p>
                    <ul className="list-unstyled">
                      <li>
                        - Finish our work for{" "}
                        <span className="aisColors"> AISCUBES </span> version 1
                      </li>
                      <li>
                        - sell your AI art at our{" "}
                        <span className="aisColors"> AISCUBES </span>{" "}
                        marketplace
                      </li>
                      <li>
                        {" "}
                        - 30% of our provision sells from{" "}
                        <span className="aisColors"> AISCUBES </span> -
                        marketplace are for our lovely community
                      </li>
                      <li>- make much more huge marketing</li>
                      <li>- preparing for 2025</li>
                    </ul>
                  </p>
                  <div className="">
                    <span class="badge rounded-pill bg-success">marketing</span>
                    <span class="badge rounded-pill bg-success">provision</span>
                    <span class="badge rounded-pill bg-success">community</span>
                    <span class="badge rounded-pill bg-success">2025</span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default RoadmapComponent;
