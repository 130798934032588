import React, { useState, useRef, useEffect } from "react";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import WhitepaperComponent from "../WhitepaperComponent/WhitepaperComponent";

import logo from "../../images/shib-ai-nu-logo.png";

import Scrollspy from "react-scrollspy";

function NavigationComponent({ fn }) {
  const [navBackground, setNavBackground] = useState(false);
  const navRef = useRef();
  navRef.current = navBackground;
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 50;
      if (navRef.current !== show) {
        setNavBackground(show);
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Scrollspy
        items={[
          "ShaiHome",
          "ShaiProject",
          "ShaiTokenomics",
          "ShaiRoadmap",
          "ShaiRewards",
          "ShaiAiscubes",
          "ShaiAboutUs",
          "ShaiFaq",
        ]}
        currentClassName="is-current"
      >
        <Navbar
          collapseOnSelect
          expand="lg"
          fixed="top"
          style={{
            transition: "1s ease",
            backgroundColor: navBackground ? "rgba(0,0,0,0.8)" : "transparent",
          }}
        >
          <Container>
            <Navbar.Brand href="#ShaiHome" className="text-white">
              <img
                src={logo}
                height="20"
                className="d-inline-block align-top"
                alt="the $AiS project"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link
                  href="#ShaiProject"
                  className="text-white aisLinkColor"
                >
                  Project
                </Nav.Link>
                <Nav.Link
                  href="#ShaiTokenomics"
                  className="text-white aisLinkColor"
                >
                  Tokenomics
                </Nav.Link>
                <Nav.Link
                  href="#ShaiRoadmap"
                  className="text-white aisLinkColor"
                >
                  Roadmap
                </Nav.Link>
                <Nav.Link
                  href="#ShaiRewards"
                  className="text-white aisLinkColor"
                >
                  Rewards
                </Nav.Link>
                <Nav.Link
                  href="#ShaiAiscubes"
                  className="text-white aisLinkColor"
                >
                  aiscubes
                </Nav.Link>
              </Nav>
              <Nav className="mx-5">
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      take a look at our FAQs
                    </Tooltip>
                  }
                >
                  <Nav.Link href="#ShaiFaq" className="text-white aisLinkColor">
                    <i class="bi bi-megaphone-fill"></i>
                  </Nav.Link>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="tooltip-disabled">visit us on Twitter</Tooltip>
                  }
                >
                  <Nav.Link
                    href="https://twitter.com/shib_ai_nu"
                    className="text-white aisLinkColor"
                    target="_blank"
                  >
                    <i class="bi bi-twitter"></i>
                  </Nav.Link>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      visit us on Telegram
                    </Tooltip>
                  }
                >
                  <Nav.Link
                    href="https://t.me/shib_ai_nu"
                    className="text-white aisLinkColor"
                    target="_blank"
                  >
                    <i class="bi bi-telegram"></i>
                  </Nav.Link>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      visit us on Instagram
                    </Tooltip>
                  }
                >
                  <Nav.Link
                    href="https://instagram.com/shib_ai_nu"
                    className="text-white aisLinkColor"
                    target="_blank"
                  >
                    <i class="bi bi-instagram"></i>
                  </Nav.Link>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="tooltip-disabled">visit us on Reddit</Tooltip>
                  }
                >
                  <Nav.Link
                    href="https://www.reddit.com/user/shib_ai_nu"
                    className="text-white aisLinkColor"
                    target="_blank"
                  >
                    <i class="bi bi-reddit"></i>
                  </Nav.Link>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      visit us on Facebook
                    </Tooltip>
                  }
                >
                  <Nav.Link
                    href="https://www.facebook.com/shibainuAi/"
                    className="text-white aisLinkColor"
                    target="_blank"
                  >
                    <i class="bi bi-facebook"></i>
                  </Nav.Link>
                </OverlayTrigger>
              </Nav>
              <Nav>
                {/* <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="tooltip-disabled">buy $SHAI token</Tooltip>
                  }
                >
                  <span className="d-inline-block">
                    <Button
                      variant="primary"
                      size="md"
                      className="shibainuButton"
                      onClick={fn}
                    >
                      <i class="bi bi-wallet"></i> connect to Metamask
                    </Button>
                  </span>
                </OverlayTrigger>*/}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Scrollspy>
    </>
  );
}

export default NavigationComponent;
